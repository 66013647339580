
import {
    AFFILIATE_INFORMATION_FORM_URL,
    BENEFICIARY_DATA_FORM_URL,
    CONFIRMATION_FORM_URL,
    EMPLOYER_FORM_URL,
    MANDATARY_INFORMATION_FORM_URL,
    PAYMENT_FORM_URL,
    PENSION_DATA_FORM_URL
} from './terminal_patients.url.constants';


/* Payment Form */

export const AFFILIATE_RADIO_BUTTON_VALUE = 'affiliate';
export const EXTERNAL_PERSON_RADIO_BUTTON_VALUE = 'externalPerson';
export const EXISTENT_MANDATARY_RADIO_BUTTON_VALUE = 'existentMandatary';

export const BANK_ACCOUNT_RUT = 'bank-account-rut';
export const BANK_ACCOUNT = 'bank-account';

export const MAPPED_SELECTOR_PAYMENT_METHOD = {
    [AFFILIATE_RADIO_BUTTON_VALUE]: 'Afiliado',
    [EXTERNAL_PERSON_RADIO_BUTTON_VALUE]: 'Mandatario de pago',
    [EXISTENT_MANDATARY_RADIO_BUTTON_VALUE]: 'Mandatario de pago'
};

export const AFFILIATED_PAYMENT_TITLE = 'Ingresa los datos del pago asociado al Afiliado';
export const MANDATARY_PAYMENT_TITLE = 'Vía de pago';
export const EXTERNAL_PERSON_PAYMENT_TITLE = 'Ingresa los datos del pago asociado al Mandatario de pago';

export const ADD_AFFILIATED_BANK_ACCOUNT_TEXT = 'Agregar cuenta bancaria Afiliado';
export const ADD_MANDATARY_BANK_ACCOUNT_TEXT = 'Agregar cuenta bancaria de';
export const ADD_EXTERNAL_PERSON_BANK_ACCOUNT_TEXT = 'Agregar cuenta bancaria Mandatario de pago';

/* Prelogin */
export const EXTERNAL_BENEFICIARY_WITHDRAWAL_URL = 'https://beneficiarios-sobrevivencia-con-et.afpcapital.cl/';
export const EXTERNAL_EARLY_WITHDRAWAL_DOUBTS_URL = 'https://www.afpcapital.cl/comunicaciones/paginas/pension-anticipada-enfermedades-terminales.aspx?utm_source=home&utm_medium=banner-home&utm_campaign=et&utm_content=enfermedad-terminal&utm_term=afp-capital';
export const SIMPLE_MANDATE_FILE_PATH = '../../../../../assets/files/Mandato simple para solicitar trámite de pensión enfermos terminales.pdf';
export const MEDICAL_COUNCIL_AUHTORIZATION_FILE_PATH = '../../../../../assets/files/Autorización para revisión de ficha clínica.pdf';

/* Login errors */

export const INVALID_SERIAL_NUMBER = 400021;
export const ZERO_BALANCE = 400023;
export const CLIENT_NOT_AFFILIATE = 400028;
export const EARLY_PENSION_REQUEST_EXISTS = 400029;
export const DECEASED_AFFILIATE = 400030;
export const NOT_APPLICABLE = 400031;
export const NOT_AVAILABLE = 400032;
export const EARLY_PENSION_REQUEST_NON_EXISTENT = 400033;
export const INVALID_EARLY_PENSION_REQUEST_NUMBER = 400034;
export const EXPIRED_SERIAL_NUMBER = 400036;
export const BLOCKED_IDENTIFICATION = 400037;

//export const DEFAULT_LOGIN_ERROR_MESSAGE = 'El usuario ha sido mal ingresado';
export const DEFAULT_LOGIN_ERROR_MESSAGE = 'Es posible que no seas cliente de AFP Capital o que no cumplas con los requisitos';
export const DEFAULT_ERROR_TITLE = 'Ha ocurrido un error';

export const MAPPED_ERRORS = {
    [INVALID_SERIAL_NUMBER]: {
        message: 'Número de serie inválido',
        title: DEFAULT_ERROR_TITLE
    },
    [EXPIRED_SERIAL_NUMBER]: {
        message: 'Cédula no vigente',
        title: DEFAULT_ERROR_TITLE
    },
    [ZERO_BALANCE]: {
        message: 'Su cuenta no está habilitada para solicitar el retiro o no cuenta con saldo disponible',
        title: DEFAULT_ERROR_TITLE
    },
    [CLIENT_NOT_AFFILIATE]: {
        message: 'No es posible continuar dado que usted no se encuentra afiliado a AFP Capital',
        title: DEFAULT_ERROR_TITLE
    },
    [EARLY_PENSION_REQUEST_EXISTS]: {
        message: 'Ya existe una solicitud de retiro anterior',
        title: DEFAULT_ERROR_TITLE
    },
    [DECEASED_AFFILIATE]: {
        message: 'No es posible continuar el Rut ingresado corresponde a un afiliado fallecido',
        title: DEFAULT_ERROR_TITLE
    },
    [NOT_APPLICABLE]: {
        message: 'Ha ocurrido un error en el sistema, contactese con el CallCenter 600-6600-900'
    },
    [NOT_AVAILABLE]: {
        message: 'Aún no se encuentra disponible esta opción',
        title: DEFAULT_ERROR_TITLE
    },
    [EARLY_PENSION_REQUEST_NON_EXISTENT]: {
        message: 'Para mayor información',
        title: 'El número de RUT no cuenta con una solicitud de enfermo terminal'
    },
    [INVALID_EARLY_PENSION_REQUEST_NUMBER]: {
        message: 'Para mayor información',
        title: 'El número de solicitud ingresado es incorrecto'
    },
    [BLOCKED_IDENTIFICATION]:{
        message: 'Cédula Bloqueada',
        title: DEFAULT_ERROR_TITLE
    }
};

/* User Types and process to Pension */

export const MAPPED_USER_TYPE = {
    A: 'AF',  // Afiliado Activo
    B: 'PV',  // Pensionado por vejez
    C: 'PIT', // Pensionado por invalidez total
    D: 'PL', // Pensionado por la Ley 16744
    E: 'PIPT', // Pensionado por invalidez parcial transitoria
    F: 'PIPD', // Pensionado por invalidez parcial definitiva
    N: 'No procede'
};

export const SHOW_EMPLOYER_DATA_BY_USER_TYPE = {
    AF: true,
    PV: false,
    PIT: false,
    PL: false,
    PIPT: true,
    PIPD: false
};

export const IS_PENSIONARY = ['PV', 'PIT', 'PL', 'PIPD'];
export const IS_DISABILITY_PENSIONED = ['PIT', 'PIPT', 'PIPD'];

export const MAPPED_PROCEDURE_TYPE = {
    VA: 'Vejez Anticipada',
    VN: 'Vejez normal',
    IN: 'Invalidez',
    RI: 'Re evaluacion',
    SO: 'Sobrevivencia',
    ET: 'Enfermedad Terminal'
};

export const MAPPED_STRING_RESPONSE_TO_BOOLEAN = {
    S: true,
    N: false,
};

/* Login Modals */

export const EXTERNAL_TRANSFER_MODAL_TITLE = '¿Deseas incorporar saldo de otras cuentas?';
export const EXTERNAL_TRANSFER_MODAL_SUBTITLE = 'Para financiar la pensión anticipada por enfermedad terminal puedes agregar,\ además del dinero de tu cuenta obligatoria, otros montos que tengas disponibles. Estos dineros pueden provenir de\ cuentas de ahorro voluntario (APV y/ó Cuenta 2) de AFP Capital u otra aseguradora, y aportes del seguro de cesantía.';
export const NO_BALANCE_MODAL_TITLE = 'No tienes saldo en tu cuenta obligatoria';
export const NO_BALANCE_MODAL_MESSAGE = 'Si no tienes saldo en tu cuenta obligatoria puedes realizar el traspaso de otra cuenta que tengas en AFP Capital, otra institución financiara y/o tu seguro de cesantía. Y sumar estos saldos en tu retiro.';
export const CONSIDERATION_MODAL_TITLE = 'Antes de continuar con el ingreso ten en cuenta';
export const AFFILIATE_IN_PROCEDURE_AND_NO_BALANCE_MODAL_MESSAGE = 'No tienes saldo en tu cuenta, \
pero puedes realizar el traspaso de otra cuenta que tengas en AFP Capital, otra institución financiara \
y/o tu seguro de cesantía, y sumar estos saldos en tu retiro. Si tu solicitud de pensión anticipada por \
enfermedad terminal es aprobada, se anularán los trámites que puedas tener en curso para pensión de vejez o de vejez anticipada';
export const IN_PROCEDURE_MESSAGE = 'Si tu solicitud de pensión anticipada por \
enfermedad terminal es aprobada, se anularán los trámites que puedas tener en curso para pensión de vejez o \
de vejez anticipada.'

export const AFILIADO_CON_MEDIDA_CAUTELAR_MODAL_MESSAGE = 'Saldo se encuentra retenido por una resolución judicial notificada de acuerdo a la Ley N° 21.484';

export const DISCLAIMER_CONSIDERATION_TEXT = 'Estoy en conocimiento de las condiciones para realizar este retiro,\ así como de los trámites en proceso y el posterior pago correspondiente. Soy consciente de que la AFP otorgará la pensión anticipada por enfermedad terminal una vez sea ingresada y recepcionada la solicitud, además de cumplir con la verificación del certificado médico emitido por el jefe de la unidad de cuidados paliativos correspondiente';

export const DISEASE_LIST_TEXT = `
•	Glioblastoma cerebral en progresión con radio y quimioterapia.<br />
•	Meduloblastoma cerebral en progresión. <br />
•	Meningitis carcinomatosa de cualquier cáncer.<br />
•	Cáncer de pulmón con metástasis a distancia múltiple.<br />
•	Cáncer de esófago en progresión.<br />
•	Cáncer gástrico metastásico a distancia en al menos dos sitios (ejemplo hígado y/o pulmón).<br />
•	Cáncer gástrico con metástasis peritoneales.<br />
•	Cáncer gástrico con metástasis hepáticas múltiples.<br />
•	Cáncer hepatobiliar con metástasis peritoneales.<br />
•	Cáncer hepatobiliar con metástasis hepáticas múltiples.<br />
•	Cáncer de intestino delgado con metástasis peritoneales.<br />
•	Cáncer de páncreas y vesícula biliar metastásico.<br />
•	Cáncer colo-rectal metastásico en progresión.<br />
•	Hepatocarcinoma avanzada sin opción de trasplante.<br />
•	Cáncer testicular metastásico en progresión a quimioterapia de segunda línea.<br />
•	Sarcoma partes blandas metastásico a distancia.<br />
•	Osteosarcoma metastásico en progresión.<br />
•	Melanoma metastásico en progresión.<br />
•	Cualquier cáncer metastásico en ECOG 4 y sin posibilidad de tratamiento sistémico.<br />
•	Cualquier cáncer con metástasis cerebral múltiple (más de 3).<br />
•	Cualquier cáncer metastásico a distancia que no puede hacerse tratamiento antineoplásico.<br />
•	Cáncer origen desconocido metastásico.`

/* Stepper */

export const MANDATARY_STEP = {
    number: 1,
    message: 'Datos mandatario',
    url: MANDATARY_INFORMATION_FORM_URL
};

export const STEPS = {
    withEmployer: [
            {
                number: 1,
                message: 'Datos afiliado',
                url: AFFILIATE_INFORMATION_FORM_URL
            },
            {
                number: 2,
                message: 'Datos de pensión',
                url: PENSION_DATA_FORM_URL
            },
            {
                number: 3,
                message: 'Datos empleador',
                url: EMPLOYER_FORM_URL
            },
            {
                number: 4,
                message: 'Beneficiarios',
                url: BENEFICIARY_DATA_FORM_URL
            },
            {
                number: 5,
                message: 'Datos pago',
                url: PAYMENT_FORM_URL
            },
            {
                number: 6,
                message: 'Confirmación',
                url: CONFIRMATION_FORM_URL
            }
        ],
    noEmployer: [
        {
            number: 1,
            message: 'Datos afiliado',
            url: AFFILIATE_INFORMATION_FORM_URL
        },
        {
            number: 2,
            message: 'Datos de pensión',
            url: PENSION_DATA_FORM_URL
        },
        {
            number: 3,
            message: 'Beneficiarios',
            url: BENEFICIARY_DATA_FORM_URL
        },
        {
            number: 4,
            message: 'Datos pago',
            url: PAYMENT_FORM_URL
        },
        {
            number: 5,
            message: 'Confirmación',
            url: CONFIRMATION_FORM_URL
        }
    ]
};

export const FORM_TITLE_TERMINAL_PATIENTS = 'Solicitud de Certificación de enfermo terminal';

/* Pension Form */

export const PENSION_DATA_TITLE = 'Situación laboral del afiliado';
export const EMPLOYMENT_STATUSES = {
    'Trabajador dependiente': 'E' ,
    'Trabajador independiente': 'I',
    // tslint:disable-next-line: object-literal-key-quotes
    'Desempleado': 'D'
};
export const RETIREMENT_CAUSE = {
    'Enfermedad traumática': 'ANL',
    'Accidente laboral o de trayecto': 'AT',
    'Enfermedad producida por el trabajo': 'EP',
    'Enfermedad común o accidente común': 'N',
};
export const FUNDS = ['A', 'B', 'C', 'D', 'E'];

export const ACCOUNT_NAMES = {
    'CUENTA DE CAPITALIZACION INDIVIDUAL DE AFILIADO VOLUNTARIO': 'CAF',
    'CUENTA DE AHORRO DE INDEMNIZACION': 'CAI',
    'CUENTA DE AHORRO VOLUNTARIO': 'CAV',
    'CUENTA DE CAPITALIZACION INDIVIDUAL DE COTIZACIONES OBLIGATORIAS': 'CCO',
    'CUENTA DE CAPITALIZACION INDIVIDUAL DE COTIZACIONES VOLUNTARIAS': 'CCV',
    'CUENTA DE CAPITALIZACION INDIVIDUAL DE DEPOSITOS CONVENIDOS': 'CDC',
    'CUENTA DE CAPITALIZACION INDIV. DE COTIZACIONES VOLUNTARIAS COLECTIVAS': 'CVC',
};

export const EXTERNAL_TRANSFER_ACCOUNTS = {
    'CUENTA DE AHORRO VOLUNTARIO': 'CAV',
    'CUENTA DE CAPITALIZACION INDIVIDUAL DE DEPOSITOS CONVENIDOS': 'CDC',
    'CUENTA DE CAPITALIZACION INDIVIDUAL DE COTIZACIONES VOLUNTARIAS': 'CCV',
    'CUENTA DE CAPITALIZACION INDIV. DE COTIZACIONES VOLUNTARIAS COLECTIVAS': 'CVC',
};

export const INTERNAL_TRANSFER_ACCOUNTS = {
    'CUENTA DE AHORRO VOLUNTARIO': 'CAV',
    'CUENTA DE CAPITALIZACION INDIVIDUAL DE COTIZACIONES VOLUNTARIAS': 'CCV',
};

export const MANDATORY_INTERNAL_TRANSFER_ACCOUNTS = {
    'CUENTA DE CAPITALIZACION INDIVIDUAL DE AFILIADO VOLUNTARIO': 'CAF',
    'CUENTA DE CAPITALIZACION INDIVIDUAL DE COTIZACIONES OBLIGATORIAS': 'CCO',
    'CUENTA DE CAPITALIZACION INDIVIDUAL DE DEPOSITOS CONVENIDOS': 'CDC',
    'CUENTA DE CAPITALIZACION INDIV. DE COTIZACIONES VOLUNTARIAS COLECTIVAS': 'CVC',
};

export const FINANCIAL_INSTITUTIONS = {
    'ZURICH ADM. GRAL. DE FONDOS S.A.': {
        type: 'AFM',
        code: 575,
    },
    'BCI SEGUROS GENERALES S.A.': {
        type: 'SEG',
        code: 5085,
    },
    'EUROAMERICA ADMINISTRADORA': {
        type: 'AGF',
        code: 113,
    },
    'ITAU CHILE ADMINISTRADORA GENERAL DE FONDOS S.A.': {
        type: 'ADM',
        code: 898,
    },
    'METLIFE CHILE SEGUROS DE VIDA': {
        type: 'SEG',
        code: 5083,
    },
    'AFP CAPITAL S.A.': {
        type: 'AFP',
        code: 1033,
    },
    'PENTA VIDA COMPAÑIA DE SEGUROS DE VIDA S.A.': {
        type: 'SEG',
        code: 6009,
    },
    'CELFIN CAPITAL S.A. CORREDORA DE BOLSA': {
        type: 'COR',
        code: 1001,
    },
    'BANCOESTADO S.A. ADMINISTRADORA GENERAL DE FONDOS': {
        type: 'AGF',
        code: 9003,
    },
    'LARRAIN VIAL S.A. CDB': {
        type: 'AFM',
        code: 6001,
    },
    'SCOTIA ADM. GENERAL DE FONDOS MUTUOS S.A.': {
        type: 'AFM',
        code: 6003,
    },
    'BANCHILE SEGUROS DE VIDA S.A.': {
        type: 'BCO',
        code: 6004,
    },
    'LARRAIN VIAL ADM. GRAL. DE FONDOS': {
        type: 'AFM',
        code: 6005,
    },
    'PENTA ADM. GRAL. DE FONDOS S.A.': {
        type: 'AFM',
        code: 6008,
    },
    'CORREDORA DE BOLSA SURA S.A.': {
        type: 'COR',
        code: 969,
    },
    'BICE VIDA COMPAÑIA DE SEGUROS DE VIDA S.A.': {
        type: 'SEG',
        code: 2500,
    },
    'BBVA ASSET MANAGEMENT AGF. S.A.': {
        type: 'AFM',
        code: 5005,
    },
    'AFP MODELO': {
        type: 'AFP',
        code: 1034,
    },
    'MONEDA CORREDORES DE BOLSA': {
        type: 'AFM',
        code: 4190,
    },
    'CORPBANCA ASSET MANAGMENT': {
        type: 'AGF',
        code: 4190,
    },
    'MBI CORREDORES DE BOLSA S.A.': {
        type: 'COR',
        code: 9692,
    },
    'CONSORCIO CORREDORES DE BOLSA S.A.': {
        type: 'COB',
        code: 7457,
    },
    'OHIO NATIONAL SEGUROS DE VIDA S.A.': {
        type: 'SEG',
        code: 2015,
    },
    'SEGUROS DE VIDA SURA S.A.': {
        type: 'SEG',
        code: 151,
    },
    'COMPASS CONSORCIO ADM. GRAL. DE FONDOS S.A.': {
        type: 'AFM',
        code: 0,
    },
    'CRUZ DEL SUR CORREDORA DE BOLSA S.A.': {
        type: 'COR',
        code: 5038,
    },
    'BANCHILE CORREDORES DE BOLSA S.A.': {
        type: 'COR',
        code: 5039,
    },
    'EUROAMERICA ADM. GRAL. DE FONDOS S.A.': {
        type: 'AFM',
        code: 5042,
    },
    'BANCHILE ADM. GRAL. DE FONDOS S.A.': {
        type: 'AFM',
        code: 5043,
    },
    'PRINCIPAL ADMINISTRADORA GENERAL DE FONDOS S.A.': {
        type: 'AFM',
        code: 5056,
    },
    'C.C.A.F.DE LOS ANDES': {
        type: 'OTR',
        code: 5059,
    },
    'CRUZ DEL SUR SEGUROS DE VIDA S.A.': {
        type: 'SEG',
        code: 155,
    },
    'PRINCIPAL CIA. DE SEGUROS DE VIDA CHILE': {
        type: 'SEG',
        code: 164,
    },
    'CHILENA CONSOLIDADA SEGUROS DE VIDA S.A.': {
        type: 'SEG',
        code: 168,
    },
    'BICE INV CORREDORES DE BOLSA S.A.': {
        type: 'COR',
        code: 4009,
    },
    'BCI SEGUROS DE VIDA S.A.': {
        type: 'SEG',
        code: 179,
    },
    'LARRAIN VIAL S.A. AFM': {
        type: 'AFM',
        code: 188,
    },
    'SECURITY ADM. GRAL DE FONDOS S.A.': {
        type: 'AFM',
        code: 189,
    },
    'CONSORCIO SEGUROS DE VIDA S.A.': {
        type: 'SEG',
        code: 166,
    },
    'BICE INVERSIONES ADM. GRAL. DE FONDOS S.A.': {
        type: 'AFM',
        code: 193,
    },
    'CELFIN ADM. GRAL DE FONDOS S.A.': {
        type: 'AFM',
        code: 196,
    },
    'SANTANDER ASSET MANAGMENTT ADM. GRAL DE FONDOS': {
        type: 'AFM',
        code: 204,
    },
    'BCI ADM. GRAL. DE FONDOS S.A.': {
        type: 'AFM',
        code: 205,
    },
    'AFP CUPRUM': {
        type: 'AFP',
        code: 1003,
    },
    'AFP HABITAT': {
        type: 'AFP',
        code: 1005,
    },
    'AFP PROVIDA': {
        type: 'AFP',
        code: 1008,
    },
    'AFP PLANVITAL': {
        type: 'AFP',
        code: 1032,
    },
    'AFP UNO': {
        type: 'AFP',
        code: 1035,
    },
    'SECURITY PREVISION': {
        type: 'SEG',
        code: 165,
    },
    'METLIFE SEGUROS DE VIDA S.A.': {
        type: 'SEG',
        code: 313,
    },
    'CRUZ DEL SUR ADM. GRAL DE FONDOS S.A.': {
        type: 'AFM',
        code: 316,
    },
    'EUROAMERICA SEGUROS DE VIDA S.A.': {
        type: 'SEG',
        code: 111,
    },
    'METLIFE CHILE SEGUROS DE VIDA S.A.': {
        type: 'SEG',
        code: 116,
    },
    'COMPAÑIA DE SEGUROS CONFUTURO S.A.': {
        type: 'SEG',
        code: 180,
    }
};

export const TERMINAL_PATIENT_FORM = {
    TramitePension: {
        numeroDocumentoAfiliado: '',
        folioSolicitud: 0,
        fechaSolicitudTramite: '',
        codigoCanal: 'W',
        codigoAgenciaSolicitud: 2022,
        nombreCalleAfiliado: '',
        numeroAfiliado: '',
        codigoComunaAfiliado: 0,
        ciudadAfiliado: '',
        codigoRegionAfiliado: 0,
        codigoInstitucionSalud: 0,
        tipoCondicionLaboral: '',
        indicadorClienteVerificado: 'S',
        idUsuarioClienteVerificado: 'USUWEBETERM',
        tipoAfiliadoWeb: '',
        indicadorAutorizaSusCalificacion: 'S',
        indicadorAutorizaSusAPS: 'S',
        indicadorSolicitaCalculoELD: 'S',
        indicadorDepConvenidoExterno: 'N',
        indicadorCotizacionVoluntariaExterna: 'N',
        indicadorCotizacionVoluntariaCVExterna: 'N',
        indicadorCotizacionVoluntariaInterna: 'N',
        indicadorSolicitaTraspasoAhorro: 'N',
        indicadorSolicitaTraspasoAhorroExterno: 'N',
        indicadorCuentaSeguroCesantia: 'N',
        indicadorSitTrabajadorDependiente: 'N',
        tipoACCLaboral: 'N',
        emailAfiliado: '',
        codigoAreaCelular: 0,
        indicadorMantieneMandatoPago: 'N',
        celularAfiliadoPrincipal: '',
        descripcionUcp: 'UCP'
    },
    AnexoTramitePension:{
        tipoLugarReposo: '',
        nombreCalleReposo: '',
        numeroCalleReposo: '',
        poblacionVillaReposo: '',
        codigoComuna: '',
        codigoCiudad: '',
        codigoRegion: '',
        indicadorCubiertoSIS: 'N',
        indicadorHijoGestacion: 'N'
    },
    Beneficiarios: [],
    Empleadores: [],
    FormasDePago: [],
    SaldosExternos: [],
    SaldosInternos: []
};

export const CONYUGE = 'Cónyuge';
export const CONVIVIENTE_CIVIL = 'Conviviente civil';
export const PAREJA_DE_HECHO = 'Pareja de hecho';
export const HIJO = 'Hijo';
export const PADRE = 'Padre';
export const MADRE = 'Madre';

export const USER_AFFILIATIONS = [CONYUGE, CONVIVIENTE_CIVIL, PAREJA_DE_HECHO, HIJO, PADRE, MADRE];
export const USER_DISABILITIES = ['Sin invalidez', 'Invalidez parcial', 'Invalidez total'];
export const USER_MARITAL_STATUSES = ['Soltero', 'Casado', 'Viudo', 'Anulado', 'Divorciado', 'Conviviente Civil'];

// tslint:disable: object-literal-key-quotes
export const MAPPED_DISABILITIES = {
    'Sin invalidez': 'N',
    'Invalidez parcial': 'IP',
    'Invalidez total': 'IT'
}

export const MAPPED_CIVIL_STATUSES = {
    'Soltero': 'S',
    'Casado': 'C',
    'Viudo': 'V',
    'Anulado': 'A',
    'Divorciado': 'D',
    'Conviviente Civil': 'P'
}

export const MAPPED_AFFILIATIONS = {
    'CONYUGE SIN HIJOS CON DERECHO': {
      affiliation: CONYUGE,
      offspring: 'N',
      code: '10'
    },
    'CONYUGE CON HIJOS CON DERECHO': {
      affiliation: CONYUGE,
      offspring: 'S',
      code: '11'
    },
    'CONYUGE VARON INVALIDO SIN HIJOS': {
      affiliation: CONYUGE,
      offspring: 'N',
      disability: 1,
      code: '12'
    },
    'CONYUGE VARON INVALIDO CON HIJOS': {
      affiliation: CONYUGE,
      offspring: 'S',
      disability: 1,
      code: '13'
    },
    'CONYUGE VARON INVALIDO PARCIAL SIN HIJOS': {
      affiliation: CONYUGE,
      offspring: 'N',
      disability: 2,
      code: '14'
    },
    'CONYUGE VARON INVALIDO PARCIAL CON HIJOS': {
      affiliation: CONYUGE,
      offspring: 'S',
      disability: 2,
      code: '15'
    },
    'CONVIVIENTE CIV. S/HIJOS CON DERECHO': {
      affiliation: CONVIVIENTE_CIVIL,
      offspring: 'N',
      code: '22'
    },
    'CONVIVIENTE CIV. C/HIJOS COMUN C/DERECHO': {
      affiliation: CONVIVIENTE_CIVIL,
      offspring: 'S',
      code: '23'
    },
    'CONVIVIENTE CIV. C/HIJOS CON DERECHO': {
      affiliation: CONVIVIENTE_CIVIL,
      offspring: 'S',
      code: '24'
    },
    'CONVIVIENTE CIV. C/HIJOS CAUS. C/DERECHO': {
      affiliation: CONVIVIENTE_CIVIL,
      offspring: 'S',
      code: '25'
    },
    'M/P FNM SIN HIJOS CON DERECHO': {
      affiliation: PAREJA_DE_HECHO,
      offspring: 'N',
      code: '20'
    },
    'M/P FNM CON HIJOS CON DERECHO': {
      affiliation: PAREJA_DE_HECHO,
      offspring: 'S',
      code: '21'
    },
    'HIJO': {
      affiliation: HIJO,
      code: '30'
    },
    'HIJO INVALIDO': {
      affiliation: HIJO,
      code: '31'
    },
    'PADRE': {
      affiliation: PADRE,
      code: '41'
    },
    'MADRE': {
      affiliation: MADRE,
      code: '42'
    }
  }

export const HEALTH_INSTITUTIONS = {
    'Nueva más Vida': 80,
    'Isapre fundación': 999,
    'BANMEDICA S.A.': 51,
    'CHUQUICAMATA':	56,
    'COLMENA GOLDEN CROSS S.A.': 58,
    'COMPENSACION':	32,
    'CONSALUD S.A.': 63,
    'CORAL': 59,
    'CRISOL S.A.': 20,
    'CRUZ DEL NORTE': 81,
    'CRUZ DEL SUR':	27,
    'DIPRECA':26,
    'EL TENIENTE-FUSAT': 55,
    'OPTIMA': 23,
    'GENESIS S.A.':	57,
    'CRUZ BLANCA': 16,
    'INSTSALUD': 19,
    'ISAGAS S.A.': 69,
    'ISAMEDICA S.A.': 64,
    'CHILENA CONSOLIDADA': 35,
    'BANCO CENTRAIL': 31,
    'CIGNA': 93,
    'SUDAMERICA DE CHILE': 40,
    'ISAPRE DEL BANCO HIPOT. DE CHI': 28,
    'GALENICA': 18,
    'ISMED': 96,
    'ISPEN': 94,
    'ISAPRE ITECO LTDA': 97,
    'LOS LAGOS': 87,
    'SHELL': 68,
    'UMBRAL': 82,
    'ISCAR LTDA.': 66,
    'ISTEL LTDA.': 75,
    'LA ARAUCANA': 30,
    'LA CUMBRE': 74,
    'LINKSALUD': 17,
    'MAS VIDA S.A.': 79,
    'MASTER SALUD S.A.': 77,
    'NATURAMED S.A.': 21,
    'NORMEDICA S.A.': 62,
    'OTRAS ISAPRES': 99,
    'AETNA SALUD S.A.':65,
    'BANCO DEL ESTADO': 67,
    'PROMEPART S.A.': 53,
    'RIO BLANCO': 60,
    'SAN LORENZO': 54,
    'SFERA': 22,
    'SUDAMERICANA': 101,
    'UNIMED': 29,
    'VIDA PLENA S.A.': 71,
    'VIDA TRES S.A.': 70,
    'FONASA': 25,
    'SANTA MARIA': 36,
};

export const BALANCE_CATEGORY = {
    'CAF': {'SALDO AFILIADO VOLUNTARIO': 'NORMAL', 'PENSION REG. ANTERIOR': 'PCAVANTERIOR', 'PENSION REG. GENERAL': 'PCAVGENERAL', 'PENSION REG. OPCIONAL': 'PCAVOPCIONAL', 'PENSION REG. OPCIONAL B': 'PCAVOPCIONALB', 'PROVISION RETIRO 10%': 'RETIRO10'},
    'CCO': {'SUBSALDO 1200': 'SUBSALDO1200', 'PENSION REG. ANTERIOR': 'PCAVANTERIOR', 'PENSION REG. GENERAL': 'PCAVGENERAL', 'PENSION REG. OPCIONAL': 'PCAVOPCIONAL', 'PENSION REG. OPCIONAL B': 'PCAVOPCIONALB', 'BONO EXONERADO ADICIONAL': 'BONOEXONERADOAD', 'NORMAL': 'NORMAL', 'BONO EXONERADO': 'BONOEXONERADO', 'SALDO RETENIDO': 'PSALDORETENIDO', 'PENSION EXTRANJERO': 'PEXTRANJERO', 'RESERVA FA': 'RESERVAFA', 'SEGURO CESANTIA': 'SEGUROCESANTIA', 'PROVISION RETIRO 10%': 'RETIRO10'},
    'CCV': {'SUBSALDO 1200': 'SUBSALDO1200', 'REGIMEN PENSION ANTIGUO': 'PANTIGUO', 'REGIMEN NUEVO': 'NUEVO', 'REGIMEN PENSION NUEVO': 'PNUEVO', 'REGIMEN ANTIGUO': 'ANTIGUO', 'PENSION EXTRANJERO': 'PEXTRANJERO', 'APORTE ESTATAL': 'ESTADO'},
    'CDC': {'REGIMEN NUEVO 2011': 'NUE2011', 'REGIMEN PENSION NUE2011': 'PNUE2011', 'REGIMEN PENSION NUEVO': 'PNUEVO', 'REGIMEN ANTIGUO': 'ANTIGUO', 'REGIMEN NUEVO': 'NUEVO', 'REGIMEN PENSION ANTIGUO': 'PANTIGUO', 'PENSION EXTRANJERO': 'PEXTRANJERO', 'REGIMEN PENSION SALDO RETENIDO': 'PNUEVOSALRET', 'REGIMEN PENSION NUE2011 SALDO RETENIDO': 'PNUE2011SALRET'},
    'CAV': {'REGIMEN 54 BIS': '54BIS', 'REGIMEN EXCESO 54 BIS': 'EXCESO54BIS', 'REGIMEN TRANSITORIO': 'TRANSITORIO', 'REGIMEN ANTERIOR': 'ANTERIOR', 'REGIMEN GENERAL': 'GENERAL', 'REGIMEN OPCIONAL A': 'OPCIONAL', 'REGIMEN OPCIONAL B': 'OPCIONALB', 'RETIRO 10%': 'RETIRO10'},
    'CAI': {'RUT EMPLEADOR': 'RUTEMPLEADOR'},
    'CVC': {'APORTE ESTATAL': 'ESTADO', 'APORTE EMPLEADOR': 'EMPLEADOR', 'APORTE AFILIADO ': 'AFILIADO'},
}

export const BENEFICIARY_ERROR_KEYS: string[] = [
    'nameError',
    'lastNameError',
    'motherLastNameError',
    'genderError',
    'rutError',
    'birthdateError',
    'addressRegionError',
    'addressCommuneError',
    'addressCityError',
    'addressStreetError',
    'matrimonyDateError',
    'maritalStatusError'
]
