import { Component } from '@angular/core';
import { IWarningModal } from '@interfaces/warningModal.interface';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-warning-modal',
  templateUrl: './warning-modal.component.html',
  styleUrls: ['./warning-modal.component.scss'],
})
export class WarningModalComponent {
  public data: IWarningModal;
  public previousWithdrawalIdentifier = false;
  public isTerminalWithdrawalError = false;
  public isTerminalWithdrawalAlert = false;
  public extraDocuments = false;

  constructor(private modalCtrl: ModalController) { }

  public dismissModal() {
    this.modalCtrl.dismiss();
  }

  public goToRequest() {
    window.open('https://retiro2.afpcapital.cl/');
  }

  public gotToExtraDocumentsErrorInformation() {
    window.open('https://www.afpcapital.cl/comunicaciones/paginas/pension-anticipada-enfermedades-terminales.aspx?utm_source=home&utm_medium=banner-home&utm_campaign=et&utm_content=enfermedad-terminal&utm_term=afp-capital');
  }
}
