export const SESSION_SECONDS = 600; // 10 minutes
export const REFRESH_TOKEN_SECONDS = 3600; // 1 hour
export const MAX_SESSION_SECONDS = 3600; // 1 hour
export const IDLE_SESSION_SECONDS = 600; // 10 minutes
export const FINISH_SESSION_TOAST_SECONDS = 5;
export const PROVISIONAL_PASSWORD_SUCCESS_SECONDS = 5;

export const EXECUTIVE_SESSION_SECONDS = 1200; // 20 minutes
export const EXECUTIVE_IDLE_SESSION_SECONDS = 1200; // 20 minutes
export const EXECUTIVE_REFRESH_TOKEN_SECONDS = 3600; // 1 hour

export const INVALID_SERIAL_NUMBER_CODE = 400021;
export const BLOCKED_CALLER_ON_LOG_REQUEST = 400024;
export const SINACOFI_QUESTIONS_NOT_FOUND = 400033;

export const UNAUTHORIZE_HTTP_STATUS_CODE = 401;
