import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';

@Injectable()
export class VersionCheckService {
    constructor(
        private update: SwUpdate,
        private appRef: ApplicationRef
    ) {}

    updateClient(): void {
        if (this.update.isEnabled) {
          this.update.available
          .subscribe(() => {
            alert('Nueva versión del sitio');
            this.update.activateUpdate().then(() => location.reload());
          });
        }
      }

      checkUpdate(frequency: number): void {
        this.appRef.isStable.subscribe((isStable) => {
          if (isStable) {
            const timeInterval = interval(frequency);
            timeInterval.subscribe(() => {
              this.update.checkForUpdate().then(() => {
                  this.updateClient();
              });
            });
          }
        })
      }
}