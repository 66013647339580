import { Subscription } from 'rxjs';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { WarningModalComponent } from '@components/warning-modal/warning-modal.component';
import { GenericToastComponent } from '@components/generic-toast/generic-toast.component';
import {
  EXECUTIVE_IDLE_SESSION_SECONDS,
  EXECUTIVE_REFRESH_TOKEN_SECONDS,
  EXECUTIVE_SESSION_SECONDS,
  FINISH_SESSION_TOAST_SECONDS, IDLE_SESSION_SECONDS, MAX_SESSION_SECONDS, REFRESH_TOKEN_SECONDS,
  SESSION_SECONDS
} from '@constants/authentication.constant';
import { USER_LOGIN_URL, EXECUTIVE_LOGIN_URL } from '@constants/url.constant';
import { IWarningModal } from '@interfaces/warningModal.interface';
import { ModalController } from '@ionic/angular';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { DataService } from '@services/data/data.service';
import { TERMINAL_PATIENTS_BASE_URL } from '@constants/terminal_patients.url.constants';
import { SUBSIDY_BASE_URL } from '@constants/subsidy.url.constants';
import { Util } from '@common/utils/util';

@Injectable()
export class SessionProvider {
  private terminalPatientUserLogged: string;
  private subsidyUserLogged: string;
  private finishSessionTimeout;
  private refreshTokenInterval;
  private activeSession: boolean;
  private idleModal;
  private onIdleStartSubscription: Subscription;
  private onIdleEndSubscription: Subscription;
  private onTimeoutSubscription: Subscription;
  private onTimeoutWarningSubscription: Subscription;

  constructor(
    private dataService: DataService,
    private modalController: ModalController,
    private router: Router,
    private ngZone: NgZone,
    private util: Util,
    private authenticationService: AuthenticationService,
    private idle: Idle,
    ) { }

  public startSession(isExecutive: boolean) {
    if (this.activeSession) { return; }
    this.setSessionModalEvents(isExecutive);
    this.setSessionEvents(isExecutive);
    this.activeSession = true;
  }

  public async finishSession(showSessionToast = true) {
    this.idle.stop();
    clearInterval(this.refreshTokenInterval);
    clearTimeout(this.finishSessionTimeout);
    this.authenticationService.firebaseSignOut();
    this.activeSession = false;

    this.checkRedirection();
    this.idleModal = null;
    await this.closeAllModals();

    if (showSessionToast) { this.openFinishSessionModal(); }
  }

  private checkRedirection() {
    this.terminalPatientUserLogged = this.dataService.get('terminal-patient-login');
    this.subsidyUserLogged = this.dataService.get('subsidy-login');
    this.dataService.clearClientData();
    if (!this.isExecutiveUser && this.terminalPatientUserLogged) {
      this.router.navigate([`${TERMINAL_PATIENTS_BASE_URL}${USER_LOGIN_URL}`]);
    } else if (!this.isExecutiveUser && this.subsidyUserLogged) {
      this.router.navigate([`${SUBSIDY_BASE_URL}${USER_LOGIN_URL}`]);
    } else if (this.isExecutiveUser) {
      this.router.navigateByUrl(EXECUTIVE_LOGIN_URL);
    } else {
      this.router.navigateByUrl(USER_LOGIN_URL);
    }
  }

  private setSessionModalEvents(isExecutive: boolean) {
    this.idle.stop();
    this.setIdleConfigurations(isExecutive);
    this.setIdleSubscriptions();
    this.idle.watch();
  }

  private setSessionEvents(isExecutive: boolean) {
    clearInterval(this.refreshTokenInterval);
    clearTimeout(this.finishSessionTimeout);

    const refreshTokenSeconds = isExecutive ? EXECUTIVE_REFRESH_TOKEN_SECONDS : REFRESH_TOKEN_SECONDS;
    this.finishSessionTimeout = setTimeout(() => this.finishSession(), this.secondsToMilliseconds(MAX_SESSION_SECONDS));
    this.refreshTokenInterval = setInterval(() =>
      this.authenticationService.refreshToken(), this.secondsToMilliseconds(refreshTokenSeconds));
  }

  private setIdleConfigurations(isExecutive: boolean) {
    if(isExecutive) {
      this.idle.setIdle(EXECUTIVE_IDLE_SESSION_SECONDS);
      this.idle.setTimeout(EXECUTIVE_SESSION_SECONDS);
    } else {
      this.idle.setIdle(IDLE_SESSION_SECONDS);
      this.idle.setTimeout(SESSION_SECONDS);
    }
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
  }

  private secondsToMilliseconds(seconds: number): number {
    const millisecondEquivalent = 1000;
    return seconds * millisecondEquivalent;
  }

  private setIdleSubscriptions() {
    if (this.onIdleStartSubscription) { this.onIdleStartSubscription.unsubscribe(); }
    if (this.onTimeoutSubscription) { this.onTimeoutSubscription.unsubscribe(); }
    if (this.onTimeoutWarningSubscription) { this.onTimeoutWarningSubscription.unsubscribe(); }
    if (this.onIdleEndSubscription) { this.onIdleEndSubscription.unsubscribe(); }

    this.onIdleStartSubscription = this.idle.onIdleStart.subscribe(() => this.showIdleWarningModal());
    this.onIdleEndSubscription = this.idle.onIdleEnd.subscribe(() => {
      if (this.idleModal) {
        this.ngZone.run(() =>
          this.idleModal.componentProps.data.description = 'Por razones de seguridad tu sesión expirará si sigues inactivo');
      }
    });
    this.onTimeoutSubscription = this.idle.onTimeout.subscribe(() => this.finishSession());
    this.onTimeoutWarningSubscription = this.idle.onTimeoutWarning.subscribe((countdown) => this.updateIdleWarningModalMessage(countdown));
  }

  private updateIdleWarningModalMessage(countdown: number) {
    if (this.idleModal) {
      const minuteEquivalent = 60;
      const timeLeft = Math.round(countdown / minuteEquivalent);
      let message = `${timeLeft} min`;
      if (timeLeft === 0) { message = `${countdown} segundos`; }

      this.idleModal.componentProps.data.description = `Por razones de seguridad tu sesión expirará dentro de ${message}`;
    }
  }

  private async showIdleWarningModal() {
    if (this.idleModal) { return; }

    this.idleModal = await this.modalController.create({
      component: WarningModalComponent,
      backdropDismiss: false,
      cssClass: 'modal generic-modal',
      componentProps: {
        data: {
          title: 'Tu sesión está por expirar',
          description: 'Por razones de seguridad tu sesión expirará dentro de 10 minutos',
          icon: 'hourglass-outline',
          primaryButtonText: 'Continuar Sesión',
          primaryButtonCallback: () => this.closeIdleModal(),
        } as IWarningModal
      }
    });
    return await this.idleModal.present();
  }

  private closeIdleModal() {
    this.idleModal.dismiss();
    this.idleModal = null;
  }

  private async closeAllModals(maxOpenModals = 5) {
    if (!maxOpenModals) { return; }
    await this.modalController.dismiss()
      .then(() => this.closeAllModals(maxOpenModals - 1))
      .catch(() => { });
  }

  private async openFinishSessionModal() {
    const modal = await this.modalController.create({
      component: GenericToastComponent,
      cssClass: 'generic-toast',
      componentProps: {
        title: 'Tu sesión expiró',
        message: 'Por razones de seguridad tu sesión expiró, por favor vuelve a ingresar'
      },
    });
    await modal.present();
    setTimeout(() => modal.dismiss(), this.secondsToMilliseconds(FINISH_SESSION_TOAST_SECONDS));
  }

  get isExecutiveUser() {
    return this.util.validExecutive();
  }
}
