import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { CanLoad, Route, Router } from '@angular/router';
import { EXECUTIVE_LOGIN_URL, USER_LOGIN_URL } from '@constants/url.constant';
import { environment } from '@env';
import { DataService } from '@services/data/data.service';

@Injectable()
export class AuthenticationGuard implements CanLoad {

  constructor(
    private dataService: DataService,
    private firebaseAuth: AngularFireAuth,
    private router: Router) { }

  async canLoad(route: Route) {
    if (environment.USING_MOCKS) { return true; }

    const terminalLogin = this.dataService.get('terminal-login');

    const userAuthenticated = await new Promise((resolve) =>
      this.firebaseAuth.auth.onAuthStateChanged((user) => resolve(!!user))) as boolean;

    if (!userAuthenticated || terminalLogin) {
      this.dataService.clear();
      if (route.path === 'ejecutivo')
        this.router.navigateByUrl(EXECUTIVE_LOGIN_URL);
      else
        this.router.navigateByUrl(USER_LOGIN_URL);
    }

    return userAuthenticated;
  }
}
