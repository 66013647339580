export const FIRST_WITHDRAWAL_NUMBER = 1;
export const SECOND_WITHDRAWAL_NUMBER = 2;
export const THIRD_WITHDRAWAL_NUMBER = 3;

export const FIRST_WITHDRAWAL = '1';
export const SECOND_WITHDRAWAL_TITLE = '2do - Retiro de Fondo de Pensiones';
export const THIRD_WITHDRAWAL_TITLE = '3er - Retiro de fondo de pensiones';
export const WITHDRAWAL_NUMBER_MAPPER = {
    1:{
        number: 1,
        title: '1er - Retiro de Fondo de Pensiones',
        firstWithdrawal: true,
        nameDataServices: 'first'
    },
    2:{
        number: 2,
        cssClass: 'second',
        title: '2do - Retiro de Fondo de Pensiones',
        secondWithdrawal: true,
        nameDataServices: 'second'        
    },
    3:{
        number: 3,
        cssClass: 'third',
        title: '3er - Retiro de fondo de pensiones',
        thirdWithdrawal: true,
        nameDataServices: 'third'        
    },
    4:{
        number: 4,
        cssClass: 'fourth',
        title: '4to - Retiro de fondo de pensiones',
        thirdWithdrawal: true,
        nameDataServices: 'fourth'
    }
}

export const ListaDeOpcionesRetiro = [    
    {
        number: 2,
        cssClass: 'second',
        title: '2do - Retiro de Fondo de Pensiones',        
        nameDataServices: 'second',
        colorNumeroRetiro : 'second',
        retiroBloqueado : false,
        retiroHabilitado : false,
        retiroDisponible : false,
        mensajeRetiroRealizado: `Ya realizaste tu segundo retiro {{ newClientPreviousWithdrawal(2) ? 'en otra AFP' : '' }}`,
        cuentaNoAptaParaRetiro : false,
        mensajeCuentaNoAptaParaRetiro : 'Cuenta no habilitada para solicitar retiro',
        containerRetiroCss : false,
        containerRetiroTitulo : '2do',
        containerRetiroDescripcionTitulo : 'Retiro de fondo de pensiones',
        containerRetiroDescripcionSubTitulo : 'Puedes retirar un mín. de UF35 en 1 cuota y máx. de UF150 hasta en 2 cuotas <b>(50% en 10 días hábiles y el otro 50% a 10 días hábiles luego del primer pago).',
    },
    {
        number: 3,
        cssClass: 'third',
        title: '3er - Retiro de fondo de pensiones',      
        nameDataServices: 'third',
        colorNumeroRetiro : 'third',
        retiroBloqueado : false,
        retiroHabilitado : false,
        retiroDisponible : false,
        mensajeRetiroRealizado: `Ya realizaste tu tercer retiro {{ newClientPreviousWithdrawal(3) ? 'en otra AFP' : '' }}`,
        cuentaNoAptaParaRetiro : false,
        mensajeCuentaNoAptaParaRetiro : 'Cuenta no habilitada para solicitar retiro',
        containerRetiroCss : false,
        containerRetiroTitulo : '3er',
        containerRetiroDescripcionTitulo : 'Retiro de fondo de pensiones',
        containerRetiroDescripcionSubTitulo : 'Puedes retirar un <b>mín. de UF35 y máx. de UF150 en 1 cuota</b>, que será pagada dentro de 15 días hábiles siguientes a la fecha de la solicitud de retiro.',
    },
    {
        number: 4,
        cssClass: 'fourth',
        title: '4to - Retiro de fondo de pensiones',        
        nameDataServices: 'fourth'
    }];

export interface IWhitrawalOption {
    number: number;
    cssClass?: string;
    title: string;
    firstWithdrawal?: boolean;
    secondWithdrawal?: boolean;
    thirdWithdrawal?: boolean;
}

export enum TitleWithdrawalDisable{
    firstWithdrawalDisable = '2do y 3er retiro',
    secondWithdrawalDisable = '3er retiro',
    defaultWithdrawalDisable = '1er, 2do y 3er retiro',
}

export enum MessageModalWithdrawalDisable{
    firstWithdrawalDisable = 'segundo o tercer',
    secondWithdrawalDisable = 'tercer',
    defaultWithdrawalDisable = 'primer, segundo o tercer',
}

export const DEFAULT_LOGIN_ERROR_MESSAGE = 'Es posible que no seas cliente de AFP Capital o que no cumplas con los requisitos';