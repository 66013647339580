import * as firebase from 'firebase/app';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { LOGIN_MOCK } from '@mocks/login.mock';
import { HttpService } from '../http/http.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthorizationProvider } from '@providers/authorization/authorization';
import { VALIDATE_SECURITY_KEY_MOCK } from '@common/mocks/validateSecurityKey.mock';
import { HttpHeaders } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { DataService } from '@services/data/data.service';
import { LoginResponse } from '../../pages/login/user/models/login.response';
import { GENERATE_TEMPORAL_PASSWORD_MOCK } from '@mocks/generateTemporalPassword.mock';
import { TerminalPatientLoginRequest, TerminalPatientLoginResponse } from 'src/app/pages/terminal-patients/login/models/login.repsonse';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private http: HttpService,
    private authorizationProvider: AuthorizationProvider,
    public firebaseAuth: AngularFireAuth,
    public dataService: DataService,
  ) { }

  public async login(data: any, type: string, recaptchaToken: string): Promise<LoginResponse> {
    let loginData: any;
    if (data.password) {
      loginData = data.applicationNumber ?
        { rut: data.rut, password: data.password, applicationNumber: data.applicationNumber, type } :
        { rut: data.rut, password: data.password, type };
    } else {
      loginData = { rut: data.rut, type };
    }
    loginData = { ...loginData, newFrontEnd: true };
    const url = `${environment.BASE_BACKEND}autenticacion/login`;
    const header = new HttpHeaders({ 'x-token': recaptchaToken });

    return this.http.post(url, loginData, LOGIN_MOCK, header).toPromise()
      .then(async (response) => {
        await this.setCurrentUserData(response.token);
        return response;
      });
  }

  public async validateSerieNumber(rut: string, serialNumber: string, recaptchaToken: string): Promise<any> {
    const data = { rut, serialNumber };
    const url = `${environment.BASE_BACKEND}pacientes-terminales/serial-number`;
    const header = new HttpHeaders({ 'x-token': recaptchaToken });
    return this.http.post(url, data, null, header).toPromise()
      .then(async (response) => {
        return response;
      });
  }

  public async terminalPatientLogin(loginData: TerminalPatientLoginRequest, recaptchaToken: string): Promise<TerminalPatientLoginResponse> {
    if (loginData.isExecutive) loginData.executiveRut = this.uid();
    const url = `${environment.BASE_BACKEND}pacientes-terminales/login`;
    const header = new HttpHeaders({ 'x-token': recaptchaToken });

    return this.http.post(url, loginData, null, header).toPromise()
      .then(async (response) => {
        await this.setCurrentUserData(response.token, true);
        return response;
      })
  }

  public async terminalRefreshAuthToken(rut: string, recaptchaToken: string): Promise<void> {
    const url = `${environment.BASE_BACKEND}pacientes-terminales/refresh-auth-token/${rut}`;
    const header = new HttpHeaders({ 'x-token': recaptchaToken });

    return this.http.get(url, null, null, header).toPromise()
      .then(async (response) => {
        await this.setCurrentUserData(response.token, true);
      });
  }

  public async changePassword(password: string, newPassword: string, oldPassword: boolean, recaptchaToken: string): Promise<void> {
    const rut = this.uid();
    const data = { rut, password, newPassword, oldPassword };
    const url = `${environment.BASE_BACKEND}pacientes-terminales/password`;
    const header = new HttpHeaders({ 'x-token': recaptchaToken });

    return this.http.patch(url, data, null, null, header).toPromise()
      .then(async (response) => {
        await this.setCurrentUserData(response.token, true);
        return response;
      })
  }

  public async validateSecurityKey(key: string, applicationNumber: number, recaptchaToken: string): Promise<void> {
    const rut = this.uid();
    const data = { rut, key, applicationNumber };
    const url = `${environment.BASE_BACKEND}autenticacion/clave-seguridad`;
    const header = new HttpHeaders({ 'x-token': recaptchaToken })

    return this.http.post(url, data, VALIDATE_SECURITY_KEY_MOCK, header).toPromise()
      .then(async (response) => {
        await this.setCurrentUserData(response.token);
      });
  }

  public async generateTemporalPassword(useEmail: boolean, data: string, applicationNumber: number, recaptchaToken: string): Promise<void> {
    const rut = this.uid();
    const body = { rut, useEmail, data, applicationNumber };
    const url = `${environment.BASE_BACKEND}autenticacion/clave-temporal`;
    const header = new HttpHeaders({ 'x-token': recaptchaToken })
    return this.http.post(url, body, GENERATE_TEMPORAL_PASSWORD_MOCK, header).toPromise();
  }

  public async validateTemporalPassword(password: string, applicationNumber: number, recaptchaToken: string): Promise<void> {
    const rut = this.uid();
    const data = { rut, password, applicationNumber };
    const url = `${environment.BASE_BACKEND}autenticacion/clave-temporal/validar`;
    const header = new HttpHeaders({ 'x-token': recaptchaToken })
    return this.http.post(url, data, VALIDATE_SECURITY_KEY_MOCK, header).toPromise()
      .then(async (response) => {
        await this.setCurrentUserData(response.token);
      });
  }

  public async refreshToken(): Promise<void> {
    const rut = this.uid();
    const data = { rut };
    const url = `${environment.BASE_BACKEND}autenticacion/refresh`;
    return this.http.post(url, data, VALIDATE_SECURITY_KEY_MOCK).toPromise()
      .then(async (response) => await this.setCurrentUserData(response.token))
      .catch(() => this.authorizationProvider.setAuthorization({ sessionActive: false, showSessionToast: true, token: null }));
  }

  public async logout(showSessionToast = false): Promise<void> {
    const header = new HttpHeaders({})
    const url = `${environment.BASE_BACKEND}autenticacion/revoke-token`;
    await this.http.post(url, null, null, header).toPromise()
      .then(response => {
        if (response == 200) {
          this.firebaseSignOut();
          this.authorizationProvider.setAuthorization({ sessionActive: false, showSessionToast, token: null });
          this.dataService.clear();
        }
      })
  }

  public uid(): string {
    const { uid } = environment.USING_MOCKS ? { uid: '19' } : this.firebaseAuth.auth.currentUser;
    return uid;
  }

  public async firebaseSignOut(): Promise<void> {
    await this.firebaseAuth.auth.signOut().catch(() => { });
  }

  public async setCurrentUserData(token: string, terminalLogin: boolean = false) {
    await this.firebaseSignOut();
    if (environment.USING_MOCKS) { return; }
    await this.firebaseAuth.auth.setPersistence(firebase.auth.Auth.Persistence.SESSION).then(() => {
      return this.firebaseAuth.auth.signInWithCustomToken(token).then(() => {
        return this.firebaseAuth.idToken.pipe(take(1)).toPromise().then((newToken) => {
          this.dataService.set('terminal-login', terminalLogin);
          this.authorizationProvider.setAuthorization({ sessionActive: true, showSessionToast: false, token: newToken });
        });
      });
    });
  }

  public async getIdToken(token: string) {

    await this.firebaseSignOut();
    await this.firebaseAuth.auth.setPersistence(firebase.auth.Auth.Persistence.SESSION).then(() => {
      return this.firebaseAuth.auth.signInWithCustomToken(token).then(() => {
        return this.firebaseAuth.idToken.pipe(take(1)).toPromise().then((newToken) => {
          sessionStorage.setItem('authorization', newToken);
        });
      });
    });
  }

  public async generateAuthToken(token: string) {
    const url = `${environment.BASE_BACKEND}autenticacion/generateAuthToken`;

    const header = new HttpHeaders().set("authorization", `Bearer ${token}`);

    return this.http.get(url, null, null, header).toPromise()
      .then(async (response) => {
        await this.getIdToken(response.token)
      })
  }
}
