export const VALIDATE_SECURITY_KEY_MOCK = {
  success: [
    {
      token: 'z4xe5cr6vt7by8nu9mi0'
    }
  ],
  failures: [{
    path: 'path',
    statusCode: 0,
    messageError: 'El servicio ha respondido con un error.'
  }]
};
