import { environment } from '@env';
// tslint:disable: max-line-length
// tslint:disable: object-literal-key-quotes
export const MIN_DESKTOP_WIDTH = 830;

export const NUMBER_REGEX = new RegExp(/^[0-9]*$/);

export const NOT_REPEAT_DIGIT = new RegExp(/^(?!([0-9])\1{5})/);

export const ALPHANUMERIC_REGEX = new RegExp(/^[a-zA-Z0-9]*$/);

export const ALPHANUMERIC_WITH_SPACES_REGEX = new RegExp(/^[a-zA-Z0-9]+[a-zA-Z0-9\s]*$/);

export const TEXT_ONLY_REGEX = new RegExp(/^[a-zA-Z\s]*$/);

export const NAME_REGEX = new RegExp(/^[a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ]+[a-zA-Z\sàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ]*$/);

export const CITY_REGEX = new RegExp(/^[a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ(),.]+[a-zA-Z\sàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ(),.]*$/);

export const ADDRESS_REGEX = new RegExp(/^[a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ(),.0-9]+[a-zA-Z\sàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ(),.0-9]*$/);

export const PHONE_PATTERN = new RegExp(/^(\+56)?(\s?)(0?9)?(\s?)[9876543]\d{7}$/);

export const EMAIL_PATTERN = new RegExp(/^(([^<>()[\]\\.,;:\s@\"]+((\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\")){2,})@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|((([a-zA-Z\-0-9]{2,})+\.)+[a-zA-Z]{2,}))$/);

export const SERIAL_NUMBER_REGEX = new RegExp(/^[A-Z]?[0-9]{9}$/);

export const ACCENTED_CHARACTERS_REGEX = new RegExp(/[àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ ]/g);

export const DATE_REGEX = RegExp(/^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/);

export const RIT_REGEX = RegExp(/^[CVEAFITWZ][-][0-9]{4}[-](((200|201)[0-9])|((2020|2021)))$/);

export const POSITIVE_INTEGER_REGEX = new RegExp(/^[1-9][0-9]*$/);

export const ALLOWED_LENGTH_ACCOUNTHOLDER_VALIDATION = {
  min: 1,
  max: 150
};

export const ALLOWED_LENGTH_NAME_VALIDATION = {
  min: 1,
  max: 50
};

export const ALLOWED_LENGTH_LASTNAME_VALIDATION = {
  min: 1,
  max: 40
};

export const ALLOWED_LENGTH_FOREIGN_PHONE_VALIDATION = {
  min: 4,
  max: 20
};

export const ALLOWED_LENGTH_PASSWORD_VALIDATION = {
  min: 4,
  max: 6
};

export const ALLOWED_LENGTH_RUT_VALIDATION = {
  min: 2,
  max: 12
};

export const ALLOWED_LENGTH_SECURITY_KEY_VALIDATION = {
  min: 6,
  max: 10
};

export const ALLOWED_LENGTH_DYNAMIC_KEY_VALIDATION = {
  min: 1,
  max: 1
};

export const ALLOWED_LENGTH_EMAIL_VALIDATION = {
  min: 3,
  max: 50
};

export const ALLOWED_LENGTH_CELLPHONE_VALIDATION = {
  min: 8,
  max: 8
};

export const MAX_CHART_LENGTH = {
  utf8: 60,
  utf16: 35
};

export const MAX_ADDRESS_NUMBER_LENGTH = {
  max: 10
};

export const ALLOWED_LENGTH_SERIAL_NUMBER_VALIDATION = {
  min: 9,
  max: 10
};

export const ALLOWED_LENGTH_ACCOUNT_VALIDATION = {
  min: 5,
  max: 20
}

export const ALLOWED_LENGTH_PREPAID_CARD_VALIDATION = {
  min: 9,
  max: 9
}

export const ALLOWED_LENGTH_ACCOUNT_FALABELLA_VALIDATION = {
  min: 11,
  max: 13,
  mid: 12
}

export const ALLOWED_LENGTH_ACCOUNT_BANCO_CHILE_VALIDATION = {
  min: 5,
  max: 12
}

export const ALLOWED_LENGTH_BANK_NAME_VALIDATION = {
  min: 5,
  max: 50
}

export const ALLOWED_LENGTH_BANK_ACCOUNT_VALIDATION = {
  min: 5,
  max: 50
}

export const ALLOWED_LENGTH_ADDRESS_VALIDATION = {
  min: 5,
  max: 100,
}

export const ALLOWED_LENGTH_CITY_VALIDATION = {
  min: 2,
  max: 50
}

export const ALLOWED_LENGTH_OLD_PASSWORD_VALIDATION = {
  min: 4,
  max: 4
};

export const ALLOWED_LENGTH_NEW_PASSWORD_VALIDATION = {
  min: 6,
  max: 6
};

export const ALLOWED_LENGTH_FOREIGN_BANK_ACCOUNT_VALIDATION = {
  min: 0,
  max: 34
}

export const CALL_CENTER_NUMBER = 'tel:23 293 4655';

export const FORGET_PASSWORD_URL = 'https://www.afpcapital.cl/Paginas/Obtener-Clave.aspx';

export const FORGET_SECURITY_KEY_URL = 'https://www.afpcapital.cl/Ayuda/Paginas/Clave-de-seguridad.aspx';

export const FOREIGN_WITHDRAWAL_INFO_EXTERNAL_URL = 'https://www.afpcapital.cl/Paginas/chilenos-residentes-en-extranjero.aspx';

export const SUBSIDY_MORE_QUESTIONS_EXTERNAL_URL = 'https://afpcapital.cl/comunicaciones/paginas/bono-200-mil.aspx?utm_source=sitio-ingreso-solicitud&utm_medium=link-informate&utm_campaign=bono-cargo-fiscal&utm_content=bono-cargo-fiscal&utm_term=afp-capital';

export const PUBLIC_FAQ_EXTERNAL_URL = 'https://informate.afpcapital.cl';

export const PUBLIC_FAQ_EXTERNAL_SECOND_WITHDRAWAL_URL = 'https://informate.afpcapital.cl/?utm_source=retiro.afpcapital.cl&utm_medium=banner-informate&utm_campaign=2do-retiro&utm_content=2do-retiro&utm_term=afp-capital';

export const PRIVATE_SITE_URL = 'https://www.afpcapital.cl/Paginas/default.aspx?IDList=1';

export const BRANCH_OFFICE_URL = 'https://www.afpcapital.cl/administracion/Paginas/sucursales.aspx';

export const HOME_AFP_URL = 'https://www.afpcapital.cl/Paginas/default.aspx';

export const SUBSIDY_FOREIGN_URL = 'https://www.afpcapital.cl/Paginas/bono-cargo-fiscal-desde-extranjero.aspx';

// QA
export const RETIRO_URL_QA = 'retiro-qa.afpcapital.cl';

export const RETIRO_URL_QA2 = 'retiro-qa2.afpcapital.cl';

export const EXECUTIVE_QA_URL = environment.EXECUTIVE_QA_URL;

export const TERMINAL_PATIENTS_QA2_URL = environment.TERMINAL_PATIENTS_QA2_URL;

export const SUBSIDY_QA_URL = environment.SUBSIDY_QA_URL;

export const TRACKING_QA_URL = environment.TRACKING_QA_URL;

export const SUBSIDY_TRACKING_QA_URL = environment.SUBSIDY_TRACKING_QA_URL;

export const TERMINAL_TRACKING_QA_URL = environment.TERMINAL_TRACKING_QA_URL;

// URL PRODUCCION PRE_PROD

export const RETIRO_URL = 'retiro.afpcapital.cl';

export const RETIRO_DESHABILITADO_URL = 'prelogin-user';

export const SURVIVAL_BENEFICIARIES_EXTERNAL_URL = 'https://beneficiarios-sobrevivencia.afpcapital.cl/';

export const SURVIVAL_SUBSIDY_BENEFICIARIES_EXTERNAL_URL = 'https://beneficiarios-sobrevivencia.afpcapital.cl/bonos';

export const EXTERNAL_ANNULMENT_URL = 'https://retiro2.afpcapital.cl/login/eliminar';

export const EXTERNAL_TRACKING_URL = 'https://retiro2.afpcapital.cl/login/consultar';

export const SUBSIDY_EXTERNAL_ANNULMENT_URL = 'https://eliminacionbonofiscal.afpcapital.cl/estado/eliminar';

export const EXECUTIVE_PRODUCTION_URL = 'retiroejecutivos.afpcapital.cl';

export const EXECUTIVE_PRODUCTION_URL_2 = 'retiroejecutivos-2.afpcapital.cl';

export const TERMINAL_PATIENTS_PRODUCTION_URL = 'solicitud-pension-enfermedad-terminal.afpcapital.cl';

export const TERMINAL_PATIENTS_PRODUCTION_URL_2 = 'solicitud-pension-enfermedad-terminal-2.afpcapital.cl';

export const SUBSIDY_PRODUCTION_URL = 'bonocargofiscal.afpcapital.cl';

export const SUBSIDY_PRODUCTION_URL_2 = 'bonocargofiscal-2.afpcapital.cl';

export const TRACKING_PROD_URL = 'https://retiro2.afpcapital.cl/login/ejecutivo';

export const SUBSIDY_TRACKING_PROD_URL = 'https://estadobonofiscal.afpcapital.cl/estado';

export const TERMINAL_TRACKING_PROD_URL = 'https://estado-solicitud-pension-enfermedad-terminal.afpcapital.cl/termial-patients-login';

export const AFP_LANDING_PAGE = 'https://www.afpcapital.cl/default.html';

export const AFP_HOME_PAGE = 'https://home.afpcapital.cl/';

export const DEFAULT_SERVICE_TIMEOUT = 30000;

export const FILE_REQUEST_TIMEOUT = 360000;

export const PAET_WITHDRAWAL_REQUEST_TIMEOUT = 720000;

export const BAD_REQUEST_HTTP_STATUS_CODE = 400;

export const INTERNAL_SERVER_ERROR_HTTP_STATUS_CODE = 500;

export const DOCUMENTS_NOT_FOUND_ERROR_CODE = 400027;

export const VALID_EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))+$/

export const LOGIN_WITH_PASS = 'USER_WITH_PASS';

export const LOGIN_WITHOUT_PASS = 'USER_WITHOUT_PASS';

export const LOGIN_EXECUTIVE = 'EXECUTIVE_USER';

export const EXECUTIVE_FISCAL_TYPE = 'F';

export const EXECUTIVE_TYPE = 'S';

export const CANT_CONTINUE_DESCRIPTION_MESSAGE = 'No es posible continuar';

export const PREVIOUS_WITHDRAWALS_ALERT_MESSAGE = '';

export const ZERO_BALANCE_ALERT_MESSAGE = 'Cuenta Obligatoria sin saldo';

export const ZERO_BALANCE_DESCRIPTION_MESSAGE = 'Su cuenta no está habilitada para realizar la solicitud de retiro, dado que no cuenta con saldo disponible';

export const NEW_CLIENT_PREVIOUS_WITHDRAWALS_ALERT_MESSAGE = '';

export const MIN_BIRTHDATE = '1900-01-01';

export const MAX_BIRTHDATE = '2015-01-01';

export const MAX_FILE_ATTACHMENTS = {
  payment: 2,
  mandataryPersonalInformation: 2,
  affiliatePersonalInformation: 2,
  unbornChildInformation: 1
}

export const MAX_FILE_SIZE = 11000000;

export const ALLOWED_FILE_TYPES = ['application/pdf', 'application/jpg', 'image/jpeg'];

export const RESOLUTION_REGEX = new RegExp(/^((100000000)|([0-9]{1,8})$){1}$/);

export const DEFAULT_VALUE_FOREIGN_BANK = 'No aplica';

export const ALLOWED_LENGTH_UPDATE_PASSWORD_VALIDATION = {
  min: 6,
  max: 6
};