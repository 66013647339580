import { IEnvironment } from '@interfaces/environment.interface';

export const environment: IEnvironment = {
  ENV: 'QA2',
  USING_MOCKS: false,
  MOCK_SUCCESS_RATE: 1,
  FIREBASE_CONFIG: {
    apiKey: "AIzaSyB3BDV_pxyH2cf34MOO72mVIFSZ0bm82N8",
    authenvironmentDomain: "afp-data-qa.firebaseapp.com",
    projectId: "afp-data-qa",
    databaseURL: "https://afp-data-qa.firebaseio.com",
    storageBucket: "afp-data-qa.appspot.com",
    messagingSenderId: "106227559678",
    appId: "1:106227559678:web:e2d7cd9de85cd9d1bb09af"    
  },
  MOCK_DELAY_MILLISECONDS: 1000,
  RECAPTCHA_SITE_KEY: '6LfHBb4eAAAAAMRcI4LaO8kQOK1jklDNNL7dvfsz',
  RECAPTCHA_ENTERPRISE_SITE_KEY: '6LfHBb4eAAAAAMRcI4LaO8kQOK1jklDNNL7dvfsz',
  RECAPTCHA_USE_ENTERPRISE: true,
  BASE_BACKEND: 'https://multiretiro-qa2.bff.afpcapital.cl/',
  TAG_MANAGER_PROJECT_ID: 'GTM-WV58S4Q',
  EXTERNAL_ANNULMENT_URL: 'https://retiro2-qa2.afpcapital.cl/login/eliminar',

  EXECUTIVE_QA_URL: 'retiroejecutivos-qa2.afpcapital.cl',
  TERMINAL_PATIENTS_QA2_URL: 'solicitud-pension-enfermedad-terminal-qa2.afpcapital.cl',
  SUBSIDY_QA_URL: 'bonocargofiscal-qa2.afpcapital.cl',
  TRACKING_QA_URL: 'https://retiro2-qa2.afpcapital.cl/login/ejecutivo',
  SUBSIDY_TRACKING_QA_URL: 'https://estadobonofiscal.qa2.afpcapital.cl/estado',
  TERMINAL_TRACKING_QA_URL: 'http://estado-solicitud-pension-enfermedad-terminal-qa2.afpcapital.cl/termial-patients-login',
  BAJAR_RETIROS: true,
  BAJAR_BONOS: false
}
