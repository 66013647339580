import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { LoadingProvider } from '@providers/loading/loading';
import { AuthorizationProvider } from '@providers/authorization/authorization';
import { SessionProvider } from '@providers/session/session';
import { IAuthorization } from '@interfaces/authorization.interface';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Meta } from '@angular/platform-browser';
import {
  EXECUTIVE_PRODUCTION_URL, EXECUTIVE_PRODUCTION_URL_2, EXECUTIVE_QA_URL, RETIRO_DESHABILITADO_URL, RETIRO_URL, RETIRO_URL_QA, RETIRO_URL_QA2, SUBSIDY_PRODUCTION_URL,
  SUBSIDY_PRODUCTION_URL_2,
  SUBSIDY_QA_URL, TERMINAL_PATIENTS_PRODUCTION_URL, TERMINAL_PATIENTS_PRODUCTION_URL_2, TERMINAL_PATIENTS_QA2_URL
} from '@constants/business.constant';
import { EXECUTIVE_LOGIN_URL } from '@constants/url.constant';
// @ts-ignore
import { version } from '../../package.json';
import { VersionCheckService } from './services/version-check/version-check.service';
import { VERSION_CHECK_FREQUENCY } from '@constants/version-check.constants';
import { TERMINAL_PATIENTS_BASE_URL } from '@constants/terminal_patients.url.constants';
import { SUBSIDY_BASE_URL } from '@constants/subsidy.url.constants';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public showLoading = false;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private meta: Meta,
    private loadingProvider: LoadingProvider,
    private authorizationProvider: AuthorizationProvider,
    private sessionProvider: SessionProvider,
    private router: Router,
    private versionCheck: VersionCheckService,
  ) {
    this.versionCheck.updateClient();
    this.meta.addTag({ name: 'Version', content: version });
    this.initializeApp();
    this.setAuthorizationSubscription();
    this.versionCheck.checkUpdate(VERSION_CHECK_FREQUENCY);
  }

  ngOnInit() {
    this.loadingProvider.getLoadingRequestIntercepted().subscribe((showLoading) => setTimeout(() => this.showLoading = showLoading, 0));
    this.addRecaptchaScript();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.isRetiroRedirect();
      this.executiveRedirect();
      this.earlyWithdrawalRedirect();
      this.subsidyRedirect();
    });
  }

  private isRetiroURL(): boolean {
    const href = window.location.hostname;
    return (href === RETIRO_URL || href === RETIRO_URL_QA || href === RETIRO_URL_QA2);
  }

  private isExecutiveURL(): boolean {
    const href = window.location.hostname;   
    return (href === EXECUTIVE_PRODUCTION_URL || href === EXECUTIVE_PRODUCTION_URL_2|| href === EXECUTIVE_QA_URL);
  }

  private isEarlyWithdrawalURL(): boolean {
    const href = window.location.hostname;
    return (href === TERMINAL_PATIENTS_QA2_URL || href === TERMINAL_PATIENTS_PRODUCTION_URL || href === TERMINAL_PATIENTS_PRODUCTION_URL_2);
  }

  private isSubsidyURL(): boolean {
    const href = window.location.hostname;
    return (href === SUBSIDY_QA_URL || href === SUBSIDY_PRODUCTION_URL || href === SUBSIDY_PRODUCTION_URL_2);
  }

  private isRetiroRedirect() {    
    if (environment.BAJAR_RETIROS)
      if (this.isRetiroURL())
        this.router.navigate([RETIRO_DESHABILITADO_URL], { replaceUrl: true });
  }

  private subsidyRedirect() {
    if (this.isSubsidyURL()) this.router.navigate([SUBSIDY_BASE_URL], { replaceUrl: true });
  }

  private earlyWithdrawalRedirect() {
    if (this.isEarlyWithdrawalURL()) this.router.navigate([TERMINAL_PATIENTS_BASE_URL], { replaceUrl: true });
  }

  private executiveRedirect() {
    if (this.isExecutiveURL()) this.router.navigate([EXECUTIVE_LOGIN_URL], { replaceUrl: true });
  }

  private setAuthorizationSubscription() {
    this.authorizationProvider.getAuthorizationRequestIntercepted()
      .subscribe((session: IAuthorization) => {
        if (session.sessionActive) { this.sessionProvider.startSession(this.isExecutiveURL()); }
        else { this.sessionProvider.finishSession(session.showSessionToast); }
      });
  }

  addRecaptchaScript() {
    const recaptchaSiteKey = environment.RECAPTCHA_USE_ENTERPRISE ?
    environment.RECAPTCHA_ENTERPRISE_SITE_KEY : environment.RECAPTCHA_SITE_KEY;
    const script = environment.RECAPTCHA_USE_ENTERPRISE ? 'enterprise.js' : 'api.js';


    const recaptchaSrc = (d: any, s: string, id: string, obj: any) => {
      
      let js: any, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = `https://www.google.com/recaptcha/${script}?render=${recaptchaSiteKey}`;
      fjs.parentNode.insertBefore(js, fjs);
    };
    recaptchaSrc(document, 'script', 'recaptcha-jssdk', this);
  }
}
