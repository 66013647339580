import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { CanLoad, Route, Router } from '@angular/router';
import { environment } from '@env';
import { SUBSIDY_BASE_URL } from '@constants/subsidy.url.constants';
import { DataService } from '@services/data/data.service';

@Injectable()
export class SubsidyAuthenticationGuard implements CanLoad {

  constructor(
    private dataService: DataService,
    private firebaseAuth: AngularFireAuth,
    private router: Router) { }

  async canLoad(route: Route) {
    if (environment.USING_MOCKS) { return true; }

    const subsidyLogin = this.dataService.get('subsidy-login');
    const userAuthenticated = await new Promise((resolve) =>
      this.firebaseAuth.auth.onAuthStateChanged((user) => resolve(!!user))) as boolean;

    if (!userAuthenticated || !subsidyLogin) {
      this.dataService.clear();
      this.router.navigate([SUBSIDY_BASE_URL]);
    }
    return userAuthenticated;
  }
}
