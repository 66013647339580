import { Component } from '@angular/core';
import { IGenericModal } from '@interfaces/genericModal.interface';


@Component({
  selector: 'app-information-modal',
  templateUrl: './information-modal.component.html',
  styleUrls: ['./information-modal.component.scss'],
})
export class InformationModalComponent  {
  public data: IGenericModal;

  constructor(){
   
  }

  get getContainer():string{
    return this.data.isUpdateKey ? "container-update-key": "container";
  }
}
