import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { InformationModalComponent } from './information-modal.component';


@NgModule({  
  imports: [
    IonicModule,
    CommonModule
  ],
  declarations: [InformationModalComponent]
})
export class InformationModalModule { }
