import { Component } from '@angular/core';

@Component({
  selector: 'app-generic-toast',
  templateUrl: './generic-toast.component.html',
  styleUrls: ['./generic-toast.component.scss'],
})
export class GenericToastComponent {
  public title: string;
  public message: string;
}
