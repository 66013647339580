import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'capitalizePipe' })
export class CapitalizePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return value; // safeguard
    const re = /(^|\s)[a-z\u00E0-\u00FC]|([.]\w[.])/g;
    return  value.toLowerCase().replace(re, (x)=>{return x.toUpperCase();});
  }
}
