import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {
  EXECUTIVE_LOGIN_URL, USER_LOGIN_URL, CLIENT_DATA_URL, CONFIRM_URL,
  VOUCHER_URL, CLIENT_VALIDATION_URL, PRE_LOGIN_EXECUTIVE, PRE_LOGIN_USER,
  USER_LOGIN_METHOD, SINACOFI_QUESTIONS_URL, CLIENT_VALIDATION_FOREIGN_URL,
  CLIENT_ABROAD_FORM_URL, CLIENT_ABROAD_CONFIRM_URL, WITHDRAW_OPTION_URL,
  EXECUTIVE_WITHDRAW_OPTION_URL, PUBLIC_AUTHORITY_URL, EXECUTIVE_PUBLIC_AUTHORITY_URL,
  SUBROGATION_FORM_CLIENT_DATA_URL, SUBROGATION_FORM_COURT_DATA_URL, SUBROGATION_FISCALIA_DATA_URL, SUBROGATION_INFO_URL,
  SUBROGATION_UPDATE_URL, TERMINAL_PATIENTS, TRANSITORY_WITHDRAW_LOGIN_URL, SUBSIDY, UPDATE_PASS
} from '@constants/url.constant';
import { AuthenticationGuard } from '@common/guards/authentication.guard';
import { ExecutiveGuard } from '@common/guards/executive.guard';
import { SubrogationGuard } from '@common/guards/subrogation.guard';
import { RetiroGuard } from '@common/guards/retiro.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: PRE_LOGIN_USER,
    pathMatch: 'full'
  },
  {
    path: USER_LOGIN_URL,
    loadChildren: './pages/login/user/login.module#LoginPageModule',
  },
  {
    path: CONFIRM_URL,
    canLoad: [AuthenticationGuard],
    loadChildren: './pages/confirm/confirm.module#ConfirmPageModule',
  },
  {
    path: EXECUTIVE_LOGIN_URL,
    canLoad: [ExecutiveGuard],
    loadChildren: './pages/login/executive/executive-login.module#ExecutiveLoginPageModule',
  },
  {
    path: CLIENT_DATA_URL,
    canLoad: [AuthenticationGuard],
    loadChildren: './pages/client-data/client-data.module#ClientDataPageModule',
  },
  {
    path: CLIENT_VALIDATION_URL,
    canLoad: [ExecutiveGuard],
    loadChildren: './pages/client-validation/client-validation.module#ClientValidationPageModule',
  },
  {
    path: CLIENT_VALIDATION_FOREIGN_URL,
    canLoad: [ExecutiveGuard],
    loadChildren: './pages/foreign-client/foreign-login/foreign-login.module#ForeignLoginPageModule',
  },
  {
    path: VOUCHER_URL,
    canLoad: [AuthenticationGuard],
    loadChildren: './pages/voucher/voucher.module#VoucherPageModule',
  },
  {
    path: PRE_LOGIN_EXECUTIVE,
    canLoad: [ExecutiveGuard],
    loadChildren: './pages/login/prelogin-executive/prelogin-executive.module#PreloginExecutivePageModule',
  },
  {
    path: PRE_LOGIN_USER,
    loadChildren: './pages/login/prelogin-user/prelogin-user.module#PreloginUserPageModule',
  },
  {
    path: USER_LOGIN_METHOD,
    loadChildren: './pages/login/user-login-method/user-login-method.module#UserLoginMethodPageModule',
  },
  {
    path: WITHDRAW_OPTION_URL,
    canLoad: [RetiroGuard],
    loadChildren: './pages/login/withdraw-option/withdraw-option.module#WithdrawOptionPageModule',
  },
  {
    path: EXECUTIVE_WITHDRAW_OPTION_URL,
    loadChildren: './pages/login/executive-withdraw-option/executive-withdraw-option.module#ExecutiveWithdrawOptionPageModule',
  },
  {
    path: PUBLIC_AUTHORITY_URL,
    canLoad: [AuthenticationGuard],
    loadChildren: './pages/login/public-authority/public-authority.module#PublicAuthorityPageModule'
  },
  {
    path: EXECUTIVE_PUBLIC_AUTHORITY_URL,
    canLoad: [ExecutiveGuard],
    loadChildren: './pages/login/executive-public-authority/executive-public-authority.module#ExecutivePublicAuthorityPageModule'
  },
  {
    path: CLIENT_ABROAD_FORM_URL,
    canLoad: [ExecutiveGuard],
    loadChildren: './pages/foreign-client/client-abroad-form/client-abroad-form.module#ClientAbroadFormPageModule'
  },
  {
    path: CLIENT_ABROAD_CONFIRM_URL,
    canLoad: [ExecutiveGuard],
    loadChildren: './pages/foreign-client/client-abroad-confirm/client-abroad-confirm.module#ClientAbroadConfirmPageModule'
  },
  {
    path: SINACOFI_QUESTIONS_URL,
    canLoad: [ExecutiveGuard],
    loadChildren: './pages/foreign-client/sinacofi-questions/sinacofi-questions.module#SinacofiQuestionsPageModule'
  },
  {
    path: SUBROGATION_FORM_CLIENT_DATA_URL,
    canLoad: [ExecutiveGuard],
    loadChildren: './pages/subrogation-client-data/subrogation-client-data.module#SubrogationClientDataPageModule'
  },
  {
    path: SUBROGATION_FORM_COURT_DATA_URL,
    canLoad: [ExecutiveGuard],
    loadChildren: './pages/subrogation-court-data/subrogation-court-data.module#SubrogationCourtDataPageModule'
  },
  {
    path: SUBROGATION_FISCALIA_DATA_URL,
    canLoad: [SubrogationGuard],
    loadChildren: './pages/fiscalia-data/fiscalia-data.module#FiscaliaDataPageModule',
  },
  {
    path: SUBROGATION_INFO_URL,
    canLoad: [SubrogationGuard],
    loadChildren: './pages/subrogation/subrogation-view/subrogation-view.module#SubrogationViewPageModule',
  },
  {
    path: SUBROGATION_UPDATE_URL,
    canLoad: [SubrogationGuard],
    loadChildren: './pages/subrogation/subrogation-update/subrogation-update.module#SubrogationUpdatePageModule',
  },
  {
    path: TERMINAL_PATIENTS,
    loadChildren: './pages/terminal-patients/terminal-patients.module#TerminalPatientsModule',
  },
  {
    path: SUBSIDY,
    loadChildren: './pages/subsidy/subsidy.module#SubsidyModule',
  },
  {
    path: TRANSITORY_WITHDRAW_LOGIN_URL,
    loadChildren: './pages/login/transitory-withdraw-login/transitory-withdraw-login.module#TransitoryWithdrawLoginPageModule',
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
