export const TERMINAL_PATIENTS_BASE_URL = 'early-withdrawal/'
export const USER_LOGIN_URL = 'login';
export const INFORMATIVE_VIEW_URL = 'prelogin';
export const USER_PASSWORD_LOGIN_URL = 'password-login';
export const USER_PROVISIONAL_PASSWORD_URL = 'provisional-password';
export const AFFILIATE_INFORMATION_FORM_URL = 'affiliate-information-form';
export const MANDATARY_INFORMATION_FORM_URL = 'mandatary-information-form';
export const BENEFICIARY_DATA_FORM_URL = 'beneficiary-data-form';
export const PAYMENT_FORM_URL = 'payment-form';
export const CONFIRMATION_FORM_URL = 'confirm-form';
export const PENSION_DATA_FORM_URL = 'pension-data-form';
export const VOUCHER_URL = 'voucher';
export const EMPLOYER_FORM_URL = 'employer';
export const OPTIONAL_DOCUMENTS_URL = 'optional-documents';
