export const CONFIRM_URL = 'confirm';
export const USER_LOGIN_URL = 'login';
export const EXECUTIVE_LOGIN_URL = 'executive-login';
export const CLIENT_DATA_URL = 'client-data';
export const CLIENT_VALIDATION_URL = 'client-validation';
export const CLIENT_VALIDATION_FOREIGN_URL = 'foreign-login';
export const VOUCHER_URL = 'voucher';
export const PRE_LOGIN_EXECUTIVE = 'prelogin-executive';
export const PRE_LOGIN_USER = 'prelogin-user';
export const USER_LOGIN_METHOD = 'user-login-method';
export const WITHDRAW_OPTION_URL = 'withdraw-option';
export const EXECUTIVE_WITHDRAW_OPTION_URL = 'executive-withdraw-option';
export const SINACOFI_QUESTIONS_URL = 'sinacofi-questions'
export const CLIENT_ABROAD_FORM_URL = 'client-abroad-form';
export const CLIENT_ABROAD_CONFIRM_URL = 'client-abroad-confirm';
export const PUBLIC_AUTHORITY_URL = 'public-authority';
export const EXECUTIVE_PUBLIC_AUTHORITY_URL = 'executive-public-authority';
export const SUBROGATION_FORM_CLIENT_DATA_URL = 'subrogation-form-client-data';
export const SUBROGATION_FORM_COURT_DATA_URL = 'subrogation-form-court-data';
export const SUBROGATION_FISCALIA_DATA_URL = 'subrogation-fiscalia-data';
export const SUBROGATION_INFO_URL = 'subrogation-info';
export const SUBROGATION_UPDATE_URL = 'subrogation-update';
export const TERMINAL_PATIENTS = 'early-withdrawal';
export const SUBSIDY = 'subsidy';
export const TRANSITORY_WITHDRAW_LOGIN_URL = 'third-withdraw-login';
export const UPDATE_PASS = 'update-pass'
