import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DataService } from '@services/data/data.service';
import { USER_LOGIN_URL } from '@constants/url.constant';

@Injectable({
  providedIn: 'root'
})
export class RetiroGuard implements CanLoad {

  constructor(
    private dataService: DataService,
    private router: Router) { }

  canLoad(route: Route) {
    const autenticacionExitosa = this.dataService.get('autenticacionExitosa');
    if (autenticacionExitosa)
      return true;
    else
      this.router.navigateByUrl(USER_LOGIN_URL);
    return autenticacionExitosa;
  }

}
