import { Injectable } from '@angular/core';
import { LOGIN_EXECUTIVE } from '@constants/business.constant';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private _data = sessionStorage;

  constructor() { }

  public clear() {
    this._data.clear();
  }

  public get(id: string) {
    const element = this._data.getItem(id);
    if (element !== 'undefined') return JSON.parse(element);
  }

  public set(id: string, data: any) {
    this._data.setItem(id, JSON.stringify(data));
  }

  public unset(id: string) {
    this._data.removeItem(id);
  }

  public clearClientData() {
    const executiveData = this.get('executive-data');
    const executiveRut = this.get('executive-rut');
    const executiveType = this.get('executive-type');
    const loginType = this.get('login-type');
    const subrogation = this.get('subrogation');
    const terminalPatientAvailable = this.get('terminal-patient-withdrawal-available');
    const subsidyForeignLogin = this.get('subsidy-foreign-login');
    const extraDocuments = this.get('extra-documents');
    const auth = sessionStorage.getItem('authorization');
    const titleWithdrawal = this.get('titleWithdrawal');  
    const isFirstWithdrawalAvailable = this.get('is-first-withdrawal-available');
    const isSecondWithdrawalAvailable = this.get('is-second-withdrawal-available');
    const mandatary = this.get('mandatary');
    this.clear();
    this.set('executive-data', executiveData);
    this.set('executive-rut', executiveRut);
    this.set('executive-type', executiveType);
    this.set('login-type', loginType);
    this.set('terminal-patient-login', null);
    this.set('subsidy-login', null);
    this.set('subrogation', subrogation);
    this.set('terminal-patient-withdrawal-available', terminalPatientAvailable);
    this.set('subsidy-foreign-login', subsidyForeignLogin);
    this.set('extra-documents', extraDocuments);
    this.set('titleWithdrawal', titleWithdrawal);
    this.set('is-first-withdrawal-available', isFirstWithdrawalAvailable);
    this.set('is-second-withdrawal-available', isSecondWithdrawalAvailable);
    this.set('mandatary', mandatary);
    this.unset('is-foreign-client');
    if (loginType === LOGIN_EXECUTIVE) { sessionStorage.setItem('authorization', auth); }
  }
}
