import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { CanLoad, Route, Router } from '@angular/router';
import { Util } from '@common/utils/util';
import { EXECUTIVE_PRODUCTION_URL, EXECUTIVE_QA_URL, EXECUTIVE_FISCAL_TYPE, LOGIN_EXECUTIVE } from '@constants/business.constant';
import { environment } from '@env';
import { DataService } from '@services/data/data.service';
import { EXECUTIVE_LOGIN_URL } from '@constants/url.constant';

@Injectable()
export class SubrogationGuard implements CanLoad {

  constructor(
    private dataService: DataService,
    private firebaseAuth: AngularFireAuth,
    private router: Router,
    private util: Util,
    ) { }

  async canLoad(route: Route) {
    if (environment.USING_MOCKS) { return true; }

    let validRut: boolean;

    const executivesUrls = [EXECUTIVE_PRODUCTION_URL, EXECUTIVE_QA_URL];
    if (environment.ENV === 'develop') executivesUrls.push('localhost');

    const executiveRut = this.util.cleanRut(this.dataService.get('executive-rut'));
    const validLoginType = this.dataService.get('login-type') === LOGIN_EXECUTIVE;
    const validExecutiveType = this.dataService.get('executive-type') === EXECUTIVE_FISCAL_TYPE;
    const validUrl = executivesUrls.includes(window.location.hostname);

    const userAuthenticated = await new Promise((resolve) =>
    this.firebaseAuth.auth.onAuthStateChanged((user) => {
        if (user) validRut = user.uid === executiveRut;
        resolve(!!user)
      })) as boolean;

    const validExecutive = validRut && validLoginType && validExecutiveType && validUrl;

    if (!userAuthenticated || !validExecutive) this.clearDataAndNavigate();
    return userAuthenticated && validExecutive;
  }

  private clearDataAndNavigate() {
    this.dataService.clear();
    this.router.navigate([EXECUTIVE_LOGIN_URL], { replaceUrl: true });
  }
}
