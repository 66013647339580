import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { IAuthorization } from '@interfaces/authorization.interface';

@Injectable()
export class AuthorizationProvider {
  private authorizationRequestSource: Subject<IAuthorization> = new Subject<IAuthorization>();
  public authorizationRequestIntercepted: Observable<IAuthorization> = this.authorizationRequestSource.asObservable();

  public getAuthorizationRequestIntercepted() {
    return this.authorizationRequestIntercepted;
  }

  public setAuthorization(session: IAuthorization) {
    return this.authorizationRequestSource.next(session);
  }
}
