import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'defaultCurrency'
})
export class DefaultCurrencyPipe extends CurrencyPipe implements PipeTransform {

  // tslint:disable-next-line: max-line-length
  transform(value: any, currencyCode?: string, display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean, digitsInfo?: string, locale?: string): string | null {
    return super.transform(
      value,
      currencyCode || 'CLP',
      display || '$',
      digitsInfo || '1.0-0',
      locale || 'es-CL'
    );
  }
}