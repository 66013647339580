import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from 'src/environments/environment';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireModule } from '@angular/fire';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { LoadingComponent } from '@components/loading/loading.component';
import { Util } from '@util';
import { CommonModule, registerLocaleData } from '@angular/common';
import { LoadingProvider } from '@providers/loading/loading';
import { HttpClientModule } from '@angular/common/http';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AuthenticationGuard } from '@common/guards/authentication.guard';
import { AuthorizationProvider } from '@providers/authorization/authorization';
import { SessionProvider } from '@providers/session/session';
import { DefaultCurrencyPipe } from '@common/pipes/default-currency.pipe';
import { CapitalizePipe } from '@common/pipes/capitalize.pipe';
import locale from '@angular/common/locales/es-CL';
import { ServiceWorkerModule } from '@angular/service-worker';
import { VersionCheckService } from './services/version-check/version-check.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TerminalPatientsAuthenticationGuard } from '@common/guards/terminal-patients-authentication.guard';
import { SubsidyAuthenticationGuard } from '@common/guards/subsidy.guard';
import { ExecutiveGuard } from '@common/guards/executive.guard';
import { SubrogationGuard } from '@common/guards/subrogation.guard';
import { InformationModalComponent } from '@components/information-modal/information-modal.component';
import { InformationModalModule } from '@components/information-modal/information-modal.module';

registerLocaleData(locale);

@NgModule({
  declarations: [
    AppComponent,
    LoadingComponent
  ],
  entryComponents: [
    InformationModalComponent
  ],
  imports: [
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireModule.initializeApp(environment.FIREBASE_CONFIG),
    BrowserModule,
    CommonModule,
    IonicModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.ENV === 'production' }),
    BrowserAnimationsModule,
    InformationModalModule
  ],
  providers: [
    AuthenticationGuard,
    TerminalPatientsAuthenticationGuard,
    SubsidyAuthenticationGuard,
    ExecutiveGuard,
    SubrogationGuard,
    AuthorizationProvider,
    DefaultCurrencyPipe,
    CapitalizePipe,
    Util,
    LoadingProvider,
    StatusBar,
    SplashScreen,
    SessionProvider,
    VersionCheckService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: 'googleTagManagerId', useValue: environment.TAG_MANAGER_PROJECT_ID },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
